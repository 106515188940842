@use 'loader';

html,
body {
    height: 100%;
}

body {
    background-color: #fbefd9;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    &.flex-centered-column,
    .flex-centered-column {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        z-index: 1;

        &.visible {
            pointer-events: auto;
            opacity: 0.2;
        }
    }

    .container {
        width: 100%;
        max-width: 480px;
        padding: 0px 20px 20px 20px;
        box-sizing: border-box;
        flex: 1 0 auto;

        @media only screen and (max-width: 380px) {
            padding: 0px 15px 15px 15px;
        }

        .output-all-container {

            position: relative;
            height: 0;
            overflow: hidden;
            transition: opacity 0.2s ease-out,
            transform 0.3s ease-out;
            opacity: 0;

            &.visible {
                display: block;
                opacity: 1;
                height: auto;
            }

            &.shrink {
                transform: scale(0.90);
                opacity: 0.5 !important;
                // transition-duration: 0.3s;
                // transition-timing-function: ease-out;
            }

            .output-canvas-container {
                position: relative;

                .example-image {
                    width:100%;
                    height:auto;
                    border-radius:15px;
                }

                .output-canvas {
                    max-width: 100%;
                    width: 500px;
                    margin: 0px auto;
                    border-radius: 10px;
                }

                .edit-human-button,
                .toggle-pets-button {
                    position: absolute;
                    bottom: 20px;
                    width: 70px;
                    height: 70px;
                    border-radius: 10px;
                    box-sizing: border-box;
                    border: 2px solid #fff;
                    background-size: cover;
                    background-position: center center;
        
                    @media only screen and (max-width: 380px) {
                        bottom: 15px;
                    }
                }

                .edit-human-button {
                    left: 20px;

                    @media only screen and (max-width: 380px) {
                        left: 15px;
                    }
                }

                .toggle-pets-button {
                    right: 20px;

                    @media only screen and (max-width: 380px) {
                        right: 15px;
                    }
                }

                .edit {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 15px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

            }

            .share-container {
                display: flex;
                justify-content: center;

                .share, .download, .download-mobile {
                    color: #fff;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    cursor: pointer;
                    width: 160px;
                    height: 40px;
                    background-color: #fe4437;
                    border-radius: 26px;
                    justify-content: center;
                    align-items: center;
                    margin: 20px 10px;
                    font-family: Inter Tight, sans-serif;
                    font-size: .8em;
                    font-weight: 600;
                    display: flex;
                }

                .download-mobile {
                    border-radius:50%;
                    width:40px;
                    height:40px;

                    .icon {
                        width:12px;
                        height:auto;
                        display: block;
                    }
                    text-decoration: none;
                }
                .share {

                    &.example-page {
                        text-decoration: none;
                        width:210px;
                    }

                    .icon {
                        width:12px;
                        height:auto;
                        margin-left:10px;
                        display: block;
                    }

                    &.hidden {
                        display:none;
                    }

                    
                }
                .download {

                    .icon {
                        width:12px;
                        height:auto;
                        margin-left:10px;
                        display: block;
                    }
                    text-decoration: none;
                    &.hidden {
                        display:none;
                    }
                }
            }

        }

        p.instruction {
            margin-top: 0;
            font-size: 0.9em;
            color: #252525;
            font-family: 'Inter Tight', sans-serif;
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;
            font-weight: 600;

            @media only screen and (max-width: 440px) {
                font-size: 0.8em;
            }

            @media only screen and (max-width: 380px) {
                font-size: 0.7em;
            }

            .circle {
                font-size: 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #252525;
                color: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }
        }

        header {

            margin: 7vh 10px;

            img {
                width: 100%;
                height: auto;
                display: block;

                &.icon {
                    max-width: 19%;
                }

                &.lockup {
                    max-width: 75%;
                }
            }

        }

        input[type=file] {
            display: none;
        }

        input[disabled] {
            pointer-events: none;
        }

        .choose-container {
            background-color: #fefbf6;
            width: 100%;
            box-sizing: border-box;
            padding: 30px;
            border-radius: 12px;
            transition: opacity 0.4s ease-in, bottom 0.4s ease-in;
            opacity: 1;

            @media only screen and (max-width: 440px) {
                padding: 20px;
            }

            @media only screen and (max-width: 380px) {
                padding: 15px;
            }

            &.fade-out {
                opacity: 0;
            }

            &.edit-mode {
                height: 60vh;
                overflow-y: scroll;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                position: fixed;
                width: calc(100vw - 30px);
                bottom: -60vh;
                left: 50%;
                border-radius: 12px 12px 0px 0px;
                transform: translateX(-50%);
                max-width: 440px;
                z-index: 2;

                &.visible {
                    // transition: bottom 0.4s ease-in;
                    bottom: 0vh;
                    transition-duration: 0.4s;
                    transition-timing-function: ease-out;
                }

                p.instruction:first-of-type {
                    display: none;
                }

                p.instruction {
                    margin-top: 5px;
                    display: flex;
                    justify-content: center;
                }

                section:first-of-type {
                    display: none;
                }

                span.circle {
                    display: none;
                }

                @media only screen and (max-width: 440px) {
                    width: calc(100vw - 40px);
                }

                @media only screen and (max-width: 380px) {
                    width: calc(100vw - 30px);
                }
            }

            .notice {
                width: 100%;
                box-sizing: border-box;
                background-color: #ffecbc;
                border-radius: 6px;
                justify-content: center;
                align-items: center;
                margin-bottom: -10px;
                padding: 8px;
                font-size: .85em;
                color: #252525;
                display: none;

                &.visible {
                    display: flex;
                }

                .exclamation {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

            .upload-square-button {
                background-color: #fe4437;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                cursor: pointer;
                background-size: cover;
                background-position: center;
                box-sizing: border-box;

                &.hidden {
                    display: none;
                }

                &.plus {
                    background-image: url('../assets/images/plus.png');
                    background-position: center;
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                }

                &.plus-dark {
                    background-image: url('../assets/images/plus-dark.png');
                    background-position: center;
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                }

                &.outline {
                    background-color: transparent;
                    border: 4px solid #fe4437;
                }

                &.border {
                    border: 4px solid #fe4437;
                }

                img {
                    width: 20px;
                    height: auto;
                }

            }

            section {
                margin: 35px 0;

                &:last-of-type {
                    margin-bottom: 0px;
                }

                p {
                    margin-top: 10px;
                    margin-bottom: 0;
                    font-size: 0.7em;
                    font-weight: 400;
                    text-transform: uppercase;
                    letter-spacing: 1.2px;

                    @media only screen and (max-width: 440px) {
                        font-size: 2.6vw;
                        letter-spacing: 0.7px;
                    }
                }

                &.grid {
                    display: grid;
                    gap: 18px;
                    grid-template-columns: repeat(3, 1fr);

                    @media only screen and (max-width: 410px) {
                        gap: 14px;
                    }

                    .grid-item {

                        display: flex;
                        flex-direction: column;

                        &.centered {
                            grid-column: 2;
                        }

                        .button {
                            padding-top: 100%;
                            /* padding trick directly on the grid item */
                            box-sizing: border-box;
                            position: relative;

                            .content {

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    background-image: url('../assets/images/tick.svg');
                                    background-size: 30px 30px;
                                    width: 30px;
                                    height: 30px;
                                    display: none;
                                }

                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;

                                img {
                                    border-radius: 20px;

                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;

                                    &.outline {
                                        box-sizing: border-box;
                                        border: 4px solid #fe4437;
                                    }
                                }

                                .tick {
                                    position: absolute;
                                    z-index: 1;
                                    width: 30px;
                                    height: 30px;
                                    top: 4px;
                                    right: 4px;
                                    transform: scale(0, 0);
                                    transition: 0.3s ease-out;

                                    &.scaled {
                                        transform: scale(1, 1);
                                    }

                                }

                            }

                        }

                        p {
                            text-align: center;
                        }

                    }

                }

            }

        }

    }

    footer {
        padding: 10px 20px 30px 20px;
        flex-shrink: 0;

        p {
            font-size: 0.8em;
            margin: 0;
            font-family: 'Inter Tight', sans-serif;
            color: #252525;
            font-weight: 600;

            a {
                color: #252525;
            }
        }

    }

}

#stage-parent-human,
#stage-parent-pet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    background-color: #f7deb1;
    border-radius: 10px;

    .container {

        background-color: #fff;
        width: 80%;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        border-radius: 10px;
        align-items: center;

        img {
            width: 50px;
            height: auto;
            border-radius: 10px;
        }

        p {
            font-family: Inter Tight, sans-serif;
            font-weight: 600;
            font-size: 0.8em;
            color: #5f4003;
            position: relative;
            margin:0px 10px 0 40px;

            @media only screen and (max-width: 440px) {
                font-size: 0.75em;
            }

            @media only screen and (max-width: 380px) {
                font-size: 0.7em;
            }

            &:before {
                content: "";
                width: 23px;
                height: 23px;
                background-image: url('../assets/images/notice.svg');
                background-size: 23px 23px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -32px;
            }
        }

    }

    .button {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        cursor: pointer;
        width: 160px;
        height: 40px;
        background-color: #fe4437;
        border-radius: 26px;
        justify-content: center;
        align-items: center;
        margin: 20px;
        font-family: Inter Tight, sans-serif;
        font-size: .8em;
        font-weight: 600;
        display: flex;

        .tick {
            width: 12px;
            height: 12px;
            background-image: url('../assets/images/tick-no-bg.png');
            background-size: 12px 12px;
            margin-left: 6px;
        }

    }
}
.loader {
  width: 48px;
  height: 48px;
  opacity: 0;
  transition: opacity .2s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader.visible {
  opacity: 1;
}

.loader:after, .loader:before {
  content: "";
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 2px solid #f99489;
  animation: rotation 2s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.loader:after {
  border-color: #ec5443;
  animation-delay: 1s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

html, body {
  height: 100%;
}

body {
  background-color: #fbefd9;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

body.flex-centered-column, body .flex-centered-column {
  flex-direction: column;
  align-items: center;
  display: flex;
}

body .overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  background-color: #000;
  transition: opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
}

body .overlay.visible {
  pointer-events: auto;
  opacity: .2;
}

body .container {
  width: 100%;
  max-width: 480px;
  box-sizing: border-box;
  flex: 1 0 auto;
  padding: 0 20px 20px;
}

@media only screen and (max-width: 380px) {
  body .container {
    padding: 0 15px 15px;
  }
}

body .container .output-all-container {
  height: 0;
  opacity: 0;
  transition: opacity .2s ease-out, transform .3s ease-out;
  position: relative;
  overflow: hidden;
}

body .container .output-all-container.visible {
  opacity: 1;
  height: auto;
  display: block;
}

body .container .output-all-container.shrink {
  transform: scale(.9);
  opacity: .5 !important;
}

body .container .output-all-container .output-canvas-container {
  position: relative;
}

body .container .output-all-container .output-canvas-container .example-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

body .container .output-all-container .output-canvas-container .output-canvas {
  max-width: 100%;
  width: 500px;
  border-radius: 10px;
  margin: 0 auto;
}

body .container .output-all-container .output-canvas-container .edit-human-button, body .container .output-all-container .output-canvas-container .toggle-pets-button {
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  border: 2px solid #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
}

@media only screen and (max-width: 380px) {
  body .container .output-all-container .output-canvas-container .edit-human-button, body .container .output-all-container .output-canvas-container .toggle-pets-button {
    bottom: 15px;
  }
}

body .container .output-all-container .output-canvas-container .edit-human-button {
  left: 20px;
}

@media only screen and (max-width: 380px) {
  body .container .output-all-container .output-canvas-container .edit-human-button {
    left: 15px;
  }
}

body .container .output-all-container .output-canvas-container .toggle-pets-button {
  right: 20px;
}

@media only screen and (max-width: 380px) {
  body .container .output-all-container .output-canvas-container .toggle-pets-button {
    right: 15px;
  }
}

body .container .output-all-container .output-canvas-container .edit {
  width: 15px;
  position: absolute;
  top: 5px;
  right: 5px;
}

body .container .output-all-container .output-canvas-container .edit img {
  width: 100%;
  height: auto;
}

body .container .output-all-container .share-container {
  justify-content: center;
  display: flex;
}

body .container .output-all-container .share-container .share, body .container .output-all-container .share-container .download, body .container .output-all-container .share-container .download-mobile {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  cursor: pointer;
  width: 160px;
  height: 40px;
  background-color: #fe4437;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  font-family: Inter Tight, sans-serif;
  font-size: .8em;
  font-weight: 600;
  display: flex;
}

body .container .output-all-container .share-container .download-mobile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
}

body .container .output-all-container .share-container .download-mobile .icon {
  width: 12px;
  height: auto;
  display: block;
}

body .container .output-all-container .share-container .share.example-page {
  width: 210px;
  text-decoration: none;
}

body .container .output-all-container .share-container .share .icon {
  width: 12px;
  height: auto;
  margin-left: 10px;
  display: block;
}

body .container .output-all-container .share-container .share.hidden {
  display: none;
}

body .container .output-all-container .share-container .download {
  text-decoration: none;
}

body .container .output-all-container .share-container .download .icon {
  width: 12px;
  height: auto;
  margin-left: 10px;
  display: block;
}

body .container .output-all-container .share-container .download.hidden {
  display: none;
}

body .container p.instruction {
  color: #252525;
  letter-spacing: .5px;
  align-items: center;
  margin-top: 0;
  font-family: Inter Tight, sans-serif;
  font-size: .9em;
  font-weight: 600;
  display: flex;
}

@media only screen and (max-width: 440px) {
  body .container p.instruction {
    font-size: .8em;
  }
}

@media only screen and (max-width: 380px) {
  body .container p.instruction {
    font-size: .7em;
  }
}

body .container p.instruction .circle {
  color: #fff;
  height: 20px;
  width: 20px;
  background-color: #252525;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: .75em;
  display: flex;
}

body .container header {
  margin: 7vh 10px;
}

body .container header img {
  width: 100%;
  height: auto;
  display: block;
}

body .container header img.icon {
  max-width: 19%;
}

body .container header img.lockup {
  max-width: 75%;
}

body .container input[type="file"] {
  display: none;
}

body .container input[disabled] {
  pointer-events: none;
}

body .container .choose-container {
  width: 100%;
  box-sizing: border-box;
  opacity: 1;
  background-color: #fefbf6;
  border-radius: 12px;
  padding: 30px;
  transition: opacity .4s ease-in, bottom .4s ease-in;
}

@media only screen and (max-width: 440px) {
  body .container .choose-container {
    padding: 20px;
  }
}

@media only screen and (max-width: 380px) {
  body .container .choose-container {
    padding: 15px;
  }
}

body .container .choose-container.fade-out {
  opacity: 0;
}

body .container .choose-container.edit-mode {
  height: 60vh;
  -webkit-overflow-scrolling: touch;
  width: calc(100vw - 30px);
  max-width: 440px;
  z-index: 2;
  border-radius: 12px 12px 0 0;
  position: fixed;
  bottom: -60vh;
  left: 50%;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: translateX(-50%);
}

body .container .choose-container.edit-mode.visible {
  transition-duration: .4s;
  transition-timing-function: ease-out;
  bottom: 0;
}

body .container .choose-container.edit-mode p.instruction:first-of-type {
  display: none;
}

body .container .choose-container.edit-mode p.instruction {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

body .container .choose-container.edit-mode section:first-of-type, body .container .choose-container.edit-mode span.circle {
  display: none;
}

@media only screen and (max-width: 440px) {
  body .container .choose-container.edit-mode {
    width: calc(100vw - 40px);
  }
}

@media only screen and (max-width: 380px) {
  body .container .choose-container.edit-mode {
    width: calc(100vw - 30px);
  }
}

body .container .choose-container .notice {
  width: 100%;
  box-sizing: border-box;
  color: #252525;
  background-color: #ffecbc;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
  padding: 8px;
  font-size: .85em;
  display: none;
}

body .container .choose-container .notice.visible {
  display: flex;
}

body .container .choose-container .notice .exclamation {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

body .container .choose-container .upload-square-button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fe4437;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

body .container .choose-container .upload-square-button.hidden {
  display: none;
}

body .container .choose-container .upload-square-button.plus {
  background-image: url("plus.e89780e3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

body .container .choose-container .upload-square-button.plus-dark {
  background-image: url("plus-dark.dd84a711.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

body .container .choose-container .upload-square-button.outline {
  background-color: rgba(0, 0, 0, 0);
  border: 4px solid #fe4437;
}

body .container .choose-container .upload-square-button.border {
  border: 4px solid #fe4437;
}

body .container .choose-container .upload-square-button img {
  width: 20px;
  height: auto;
}

body .container .choose-container section {
  margin: 35px 0;
}

body .container .choose-container section:last-of-type {
  margin-bottom: 0;
}

body .container .choose-container section p {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: .7em;
  font-weight: 400;
}

@media only screen and (max-width: 440px) {
  body .container .choose-container section p {
    letter-spacing: .7px;
    font-size: 2.6vw;
  }
}

body .container .choose-container section.grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  display: grid;
}

@media only screen and (max-width: 410px) {
  body .container .choose-container section.grid {
    gap: 14px;
  }
}

body .container .choose-container section.grid .grid-item {
  flex-direction: column;
  display: flex;
}

body .container .choose-container section.grid .grid-item.centered {
  grid-column: 2;
}

body .container .choose-container section.grid .grid-item .button {
  box-sizing: border-box;
  padding-top: 100%;
  position: relative;
}

body .container .choose-container section.grid .grid-item .button .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body .container .choose-container section.grid .grid-item .button .content:before {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url("tick.08eadf93.svg");
  background-size: 30px 30px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body .container .choose-container section.grid .grid-item .button .content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

body .container .choose-container section.grid .grid-item .button .content img.outline {
  box-sizing: border-box;
  border: 4px solid #fe4437;
}

body .container .choose-container section.grid .grid-item .button .content .tick {
  z-index: 1;
  width: 30px;
  height: 30px;
  transition: all .3s ease-out;
  position: absolute;
  top: 4px;
  right: 4px;
  transform: scale(0);
}

body .container .choose-container section.grid .grid-item .button .content .tick.scaled {
  transform: scale(1);
}

body .container .choose-container section.grid .grid-item p {
  text-align: center;
}

body footer {
  flex-shrink: 0;
  padding: 10px 20px 30px;
}

body footer p {
  color: #252525;
  margin: 0;
  font-family: Inter Tight, sans-serif;
  font-size: .8em;
  font-weight: 600;
}

body footer p a {
  color: #252525;
}

#stage-parent-human, #stage-parent-pet {
  width: 100%;
  max-width: 500px;
  background-color: #f7deb1;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

#stage-parent-human .container, #stage-parent-pet .container {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 5px;
  display: flex;
}

#stage-parent-human .container img, #stage-parent-pet .container img {
  width: 50px;
  height: auto;
  border-radius: 10px;
}

#stage-parent-human .container p, #stage-parent-pet .container p {
  color: #5f4003;
  margin: 0 10px 0 40px;
  font-family: Inter Tight, sans-serif;
  font-size: .8em;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 440px) {
  #stage-parent-human .container p, #stage-parent-pet .container p {
    font-size: .75em;
  }
}

@media only screen and (max-width: 380px) {
  #stage-parent-human .container p, #stage-parent-pet .container p {
    font-size: .7em;
  }
}

#stage-parent-human .container p:before, #stage-parent-pet .container p:before {
  content: "";
  width: 23px;
  height: 23px;
  background-image: url("notice.f3afbcdb.svg");
  background-size: 23px 23px;
  position: absolute;
  top: 50%;
  left: -32px;
  transform: translateY(-50%);
}

#stage-parent-human .button, #stage-parent-pet .button {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  cursor: pointer;
  width: 160px;
  height: 40px;
  background-color: #fe4437;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  margin: 20px;
  font-family: Inter Tight, sans-serif;
  font-size: .8em;
  font-weight: 600;
  display: flex;
}

#stage-parent-human .button .tick, #stage-parent-pet .button .tick {
  width: 12px;
  height: 12px;
  background-image: url("tick-no-bg.a71b3321.png");
  background-size: 12px 12px;
  margin-left: 6px;
}

/*# sourceMappingURL=example.8ec0ecb4.css.map */

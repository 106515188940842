.loader {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    &.visible {
        opacity: 1;
    }
}

.loader::after,
.loader::before {
    content: '';
    width: 48px;
    height: 48px;
    border: 2px solid #f99489;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    animation: rotation 2s ease-in-out infinite;
}

.loader::after {
    border-color: #ec5443;
    animation-delay: 1s;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}